import React, { useState } from 'react';
import axios from 'axios';


export default function DataDisplay() {
  const [result, setResult] = useState('');
  const [obj, setObject] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.get('https://jsonplaceholder.typicode.com/todos');
      setResult(JSON.stringify(response.data));
      setObject(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
      setResult('Error fetching data');
    }
  };
  
  return (
    <div>
      <button onClick={fetchData}>TestApi</button>
      <br />
      <textarea
        value={result}
        rows={10}
        cols={50}
        readOnly
        style={{ marginTop: '10px' }}
      />
      <textarea
        value={obj[0]}
        rows={10}
        cols={50}
        readOnly
        style={{ marginTop: '10px' }}
      />
    </div>
  )
}
